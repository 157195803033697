import { React, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { axiosInstance } from "../../controller/axiosInterceptor";

const ContactUsHome = ({ navRef }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  const finishHandler = async (values) => {
    try {
      const response = await axiosInstance.post("/contactus", values);
      if (response.data.status == 200) {
        message.success("Thank you! We will get back to you.");
        form.resetFields();
        setUserName("");
        setEmail("");
        setContactMessage("");
      }
    } catch (error) {
      message.error("Email sent failed.");
      console.error("Error submitting form:", error);
    }
  };

  // ?to avoid numbers in input tag
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;

    // Allow alphabetic characters and spaces
    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      charCode === 32
    ) {
      // Allow the key press
    } else {
      // Prevent the key press
      e.preventDefault();
    }
  };

  return (
    <>
      <div ref={navRef}></div>
      <div >
        <div className="row align-items-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 common-heading mb-0">
            <div className="heading-tag">Reach Out to Us!</div>
            <h5 className="position-relative">
              <div className="arrow-icon-up position-absolute">
                <img
                  src="/assets/icons/layer-arrow-up.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              Unlock Endless Possibilities: Connect with Us to Explore a World
              of Opportunities and Seamless Collaboration!
            </h5>
            <p>
              At Last Minute App, we value your inquiries and feedback. Our
              dedicated team is here to assist you on your journey. Whether you
              seek partnership opportunities, have questions, or simply want to
              connect, we welcome the chance to engage in a meaningful
              conversation. Reach out today, and let's build something
              extraordinary together!
            </p>
            {/* <ul className="list-inline mb-0 position-relative">
              <li className="list-inline-item">
                <a href={" https://www.facebook.com/"}>
                  <img
                    src="/assets/icons/facebook-primary-icon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a href={" https://www.twitter.com/"}>
                  <img
                    src="/assets/icons/twitter-primary-icon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a href={" https://www.instagram.com/"}>
                  <img
                    src="/assets/icons/instagram-primary-icon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </li>
              <li className="list-inline-item">
                <a href={" https://www.linkedin.com/"}>
                  <img
                    src="/assets/icons/linkedin-primary-icon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </li>

              <li className="list-inline-item">
                <a href={" https://www.youtube.com/"}>
                  <img
                    src="/assets/icons/youtube-primary-icon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </li>

              <div className="position-absolute d-block d-sm-block d-md-none d-xl-none d-lg-none arrow-down-div">
                <img
                  src="/assets/icons/down-arrow-layer-mobile.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </ul> */}
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 contact-us-details">
            <h6>Enter your details to get in touch with us.</h6>
            <div className="row">
              <div className="col-12 common-form-div">
                <Form autoComplete="off" onFinish={finishHandler} form={form}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <Form.Item
                        className="position-relative"
                        label={false}
                        name="userName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username!",
                          },
                          {
                            pattern: "^[A-Za-z ]+$",
                            message: "Username must contain only characters!",
                          },
                        ]}
                      >
                        <Input
                          value={userName}
                          placeholder="Enter your name"
                          onKeyPress={handleKeyPress}
                          onChange={(e) => setUserName(e.target.value)}
                          maxLength={15}
                          prefix={
                            <div className="icon-beige-div">
                              <img
                                src="/assets/icons/contact-profile.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          }
                        />
                      </Form.Item>
                      {/*  */}
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <Form.Item
                        className="position-relative"
                        label={false}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid E-mail.",
                          },
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          prefix={
                            <div className="icon-beige-div">
                              <img
                                src="/assets/icons/mail-icon-primary.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        label={false}
                        name="contactMessage"
                        rules={[
                          {
                            required: true,
                            message: "Please input your message!",
                          },
                        ]}
                      >
                        <TextArea
                          value={contactMessage}
                          className="textarea-message"
                          placeholder="Enter your message"
                          onChange={(e) => setContactMessage(e.target.value)}
                          maxLength={255}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item className="mb-0">
                        <Button className="common-btn" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsHome;
