import React, { useState } from "react";

import { Button } from "antd";

import SignUpModal from "../modals/auths/SignUpModal";
import SignInModal from "../modals/auths/SignInModal";

const SkyQuickWeb = ({ aboutRef }) => {
  const [isSignUpOpen, setIsSignUpOpen] = useState();
  const [isSignInOpen, setIsSignInOpen] = useState();

  const showModalSignIn = (visible) => {
    setIsSignInOpen(visible);
  };

  const showModalSignup = (visible) => {
    setIsSignUpOpen(visible);
  };

  return (
    <>
      <div ref={aboutRef}></div>

      <div style={{ padding: "47px" }}></div>
      <div>
        <div className="row h-100 align-items-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="row sky-web-details common-heading mb-0">
              <div className="col-12">
                <div className="heading-tag">Our Unique Story</div>

                <h5>Crafting Excellence Together</h5>

                <p>
                  At Last Minute App, passion fuels our journey. We are a
                  collective force, dedicated to crafting exceptional
                  experiences. Rooted in innovation and collaboration, our team
                  strives to redefine industry standards, creating a platform
                  where service seekers and providers converge for unparalleled
                  excellence.
                </p>

                <Button
                  className="common-btn"
                  onClick={() => showModalSignup(true)}
                >
                  Explore More
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sky-web-img">
            <img
              alt=""
              className="img-fluid"
              src="/assets/images/sky-web-img.png"
            />
          </div>
        </div>

        <SignUpModal
          visible={isSignUpOpen}
          onClose={() => showModalSignup(false)}
          openSignUpModal={() => showModalSignup(true)}
          openSignInModal={() => showModalSignIn(true)}
        />
        <SignInModal
          visible={isSignInOpen}
          onClose={() => showModalSignIn(false)}
          openSignUpModal={() => showModalSignup(true)}
        />
      </div>
    </>
  );
};

export default SkyQuickWeb;
