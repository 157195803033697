import React, {useState} from "react";
import {Button} from "antd";
import SignUpModal from "../modals/auths/SignUpModal";
import SignInModal from "../modals/auths/SignInModal";
import {Link} from "react-router-dom";

const HomeBanner = ({homeRef}) => {
    const [isSignUpOpen, setIsSignUpOpen] = useState();
    const [isSignInOpen, setIsSignInOpen] = useState();

    const showModalSignup = (visible) => {
        setIsSignUpOpen(visible);
    };
    const showModalSignIn = (visible) => {
        setIsSignInOpen(visible);
    };

    return (<>
            <div ref={homeRef}></div>
            <div className="container">
                <div className="row mx-0 align-items-end">
                    <div className="col-12 col-sm-12 col-md-6">
                        <div className="row banner-row-details">
                            <div className="col-12">
                                <h1 className="position-relative">
                                    <img
                                        src="/assets/icons/rounded-line.png"
                                        alt=""
                                        className="img-fluid rounded-shape-img"
                                    />
                                    <span className="boost-color">Boost</span> Your Business Fast{" "}
                                    <div className="font-normal-custom">with</div>
                                    <span className="ease-color">Ease</span>
                                    <img
                                        src="/assets/icons/arrow-long-shape.svg"
                                        alt=""
                                        className="img-fluid line-arrow-shape-img"
                                    />
                                </h1>
                                <div className="details-inner w-100">
                                    <div className="details-img">
                                        <img
                                            src="/assets/images/banner-small-img.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="details-des">
                                        <img
                                            src="/assets/images/shape-banner-round.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                        <p>
                                            Experience seamless convenience with our on-demand platform
                                            connecting service seekers and providers. Effortlessly book
                                            a variety of services, while providers set their
                                            availability to match their schedule. Enjoy a hassle-free
                                            experience, bringing together demand and supply in one
                                            intuitive app for your every service need.
                                        </p>
                                        <div className="d-flex gap-2">
                                            <Link to="https://apps.apple.com/app/last-minute-application/id6504287671">
                                                <img
                                                    src="/assets/images/ios.png"
                                                    className="store-btn-img"
                                                />
                                            </Link>
                                            <Link to="https://play.google.com/store/apps/details?id=com.lma.app">
                                                <img
                                                    src="/assets/images/android.png"
                                                    className="store-btn-img"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 banner-right-div">
                        <img
                            src="/assets/images/banner-big-img.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <SignUpModal
                visible={isSignUpOpen}
                onClose={() => showModalSignup(false)}
                openSignUpModal={() => showModalSignup(true)}
                openSignInModal={() => showModalSignIn(true)}
            />
            <SignInModal
                visible={isSignInOpen}
                onClose={() => showModalSignIn(false)}
                openSignUpModal={() => showModalSignup(true)}
            />
        </>);
};

export default HomeBanner;
