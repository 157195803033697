import React from "react";
import GetWorkNearby from "./svgs/getWorkNearby";
import GetUnlimitedWork from "./svgs/getUnlimitedWork";
import GetPaid from "./svgs/getPaid";

const HowItWorks = ({ howRef }) => {
  return (
    <>
      <div ref={howRef}></div>
      <div style={{ paddingTop: "16px" }}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-10 text-center mx-auto common-heading">
            <div className="heading-tag">Connect. Book. Thrive</div>
            <h5>How It Works</h5>
            <p>
              Effortlessly access our on-demand platform in three simple steps:
              Explore services tailored to your needs, seamlessly book
              appointments based on provider availability, and experience
              convenience like never before. Our intuitive system connects users
              and providers, ensuring a smooth and efficient process for all
              your service requirements.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 work-card">
            <div className="row mx-0 work-card-row">
              <div className="col-12">
                <div className="work-icon-div">
                  <GetWorkNearby />
                </div>
                <h5>Local Opportunities Await</h5>
                <p>
                  Discover service requests near you, ensuring you work in your
                  preferred area. Maximize efficiency and minimize travel time,
                  offering your expertise where it matters most.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 work-card">
            <div className="row mx-0 work-card-row">
              <div className="col-12">
                <div className="work-icon-div">
                  <GetUnlimitedWork />
                </div>
                <h5>Effortless Work, Endless Growth</h5>
                <p>
                  Unlock a continuous stream of service opportunities. Enjoy the
                  flexibility of taking on as much work as you desire, boosting
                  your business and professional portfolio with every completed
                  task.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 work-card">
            <div className="row mx-0 work-card-row">
              <div className="col-12">
                <div className="work-icon-div">
                  <GetPaid />
                </div>
                <h5>Earn with Confidence</h5>
                <p>
                  With our seamless payment system, you receive compensation
                  immediately after completing the service. Focus on delivering
                  quality, knowing you'll be fairly rewarded for your expertise
                  and dedication to customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
