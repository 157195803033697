import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Upload, List } from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import UploadImage from "./UploadImage";
import { getAddressObject } from "../../../../common/helper/getAddressObject";
import GoogelComponent from "../../../../common/helper/GoogelComponent";
import PhoneInput from 'react-phone-input-2'
import SpinWrapper from "../../../../common/Loader/SpinWrapper";
import 'react-phone-input-2/lib/style.css'
import "../../../../styles/form.scss";
import axios from "axios";
import { axiosInstance } from "../../../../controller/axiosInterceptor";

const BusinessDetails = (props) => {
  const {
    profileData,
    form1Ref,
    form,
    setAddress,
    adress,
    current,
    showMap,
    setbusinessPdf,
    setphone,
    phone,
    setCountryCode,
    setDisabled,
  } = props;
  const [value, setValue] = useState("");
  const [showList, setShowList] = useState(false);
  const [lat, setLat] = useState();
  const [pdf, setPdf] = useState("");
  const [lon, setLon] = useState();
  const [loader, setLoader] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API,
    options: {
      componentRestrictions: { country: ["ca", "USA", "IN"] },
    },
  });

  const initialValues = {
    adress: adress?.address ? adress?.address : "",
    city: adress?.city ? adress?.city : "",
    region: adress?.region ? adress?.region : "",
    postal_code: adress?.postal_code ? adress?.postal_code : "",
    lat: adress?.lat ? adress?.lat : "",
    lon: adress?.lon ? adress?.lon : "",
  };

  useEffect(() => {
    if (profileData?._id) {
      form1Ref.setFieldsValue({
        ...profileData,
        ...profileData?.address,
      });
    }
  }, [profileData?._id]);

  const handleDocumentUpload = async (options) => {
    const { file } = options;
    try {
      const formData = new FormData();
      formData.append("image", file);
      const token = localStorage.getItem("accessToken");
      setLoader(true)
      const response = await axios
        .post(`${process.env.REACT_APP_BASE_URL}/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            accessToken: token,
            // eslint-disable-next-line no-useless-concat
            Authorization: "Basic " + "M3JhZCQ5JSFAMyMhOjlyYSQ5JSFAMyEpJiMkQA==",
          },
        })
      if (response.data?.status == 200) {
        setbusinessPdf(response.data.output?.imageUrl[0]?.location);
        message.success(`${file.name} uploaded successfully`);
        setPdf(`${file.name}`);
        setLoader(false)
      }
    } catch (error) {
      message.error("File upload failed");
    }
  };

  const validateNumber = (_, value) => {
    if (!value || /^[0-9]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please input only numbers"));
  };

  const handleFormChange = () => {
    props.setDisabled(true);
    const values = props.form.getFieldValue();
    const hasErrors =
      !props.form.isFieldsTouched(true) ||
      props.form.getFieldsError().filter(({ errors }) => errors.length).length >
      0;
    props.setDisabled(hasErrors);

    if (
      phone && phone?.length >= 9 &&
      values?.businessName &&
      values?.street &&
      values?.city &&
      values?.postal_code &&
      values?.country &&
      values?.taxId &&
      values?.profileImage &&
      values?.businessDocument
    ) {
      props.setDisabled(false);
    } else {
      props.setDisabled(true);
    }
  };

  const getDetails = (placeid, props) => {
    const data = placePredictions.filter((item) => item.place_id === placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        setLat(placeDetails.geometry.location.lat().toString());
        setLon(placeDetails.geometry.location.lng().toString());
        const address = getAddressObject(placeDetails?.address_components);
        if (placeDetails?.name) setValue(placeDetails?.name);
        else setValue(`${address?.city} ${address?.region} ${address?.country} ${address?.postal_code}`);

        setAddress({
          address: placeDetails?.name,
          city: address?.city,
          region: address?.region,
          postal_code: address?.postal_code,
          country: address?.country,
          lat: placeDetails.geometry.location.lat().toString(),
          lon: placeDetails.geometry.location.lng().toString(),
        });
        form.setFieldsValue(address);
        setShowList(false);
        handleFormChange()

        return form.isValid;
      }
    );
  };

  return (
    <>
      {current === 1 && !showMap && (<>
        <div className="row">
          <div className="col-12 text-center steps-form-heading">
            <h5>Enter your business details</h5>
          </div>
        </div>
        <SpinWrapper loader={loader}>
          <div className="row">
            <div className="col-12 common-form-div form-create-profile">
              <Form
                name="basic"
                initialValues={initialValues}
                autoComplete="off"
                form={form1Ref}
                onFieldsChange={handleFormChange}
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <Form.Item
                      label={false}
                      name="profileImage"
                      rules={[
                        {
                          required: true,
                          message: "Please Select business pics",
                        },
                      ]}
                    >
                      <UploadImage
                        {...props}
                        currentImage={profileData?.profileImage}
                        fieldName="profileImage"
                        setLoader={setLoader}
                        handleFormChange={handleFormChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name="businessName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your business name!",
                        },
                        {
                          pattern: /^[a-zA-Z0-9 ]+$/, // Removed unnecessary `i` flag
                          message: "Invalid business name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your business name" maxLength={36} />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 mobile-no-div">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                        {
                          validator: validateNumber,
                        },
                      ]}
                    >
                      <PhoneInput
                        country={"us"}
                        value={phone}
                        className="ant-input"

                        onChange={(value, country, e, formattedValue) => {
                          setCountryCode(country?.dialCode);
                          setphone(value.slice(country?.dialCode?.length));
                        }}
                        id="custommobile"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      rules={[
                        {
                          required: true,
                          message: "Please input your street address!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        style={{ color: "black", height: "60px" }}
                        name="adress"
                        value={value}
                        autoFocus
                        onChange={(evt) => {
                          setValue(evt.target.value)
                          getPlacePredictions({ input: evt.target.value });
                          if (!isPlacePredictionsLoading) setShowList(true)
                        }}

                        onKeyDown={(event) => {
                          if (event?.keyCode === 8 || event?.keyCode === 46) {
                            form?.setFieldValue("city", '')
                            form?.setFieldValue("region", '')
                            form?.setFieldValue("postal_code", '')
                            handleFormChange()
                          }
                        }}
                        loading={false}
                        placeholder="Enter your address"
                      />
                      {value !== '' && showList && placePredictions.length > 0 && (
                        <div className="google-autosuggestion-list">
                          <List
                            dataSource={placePredictions}
                            renderItem={(item) => (
                              <>
                                <List.Item onClick={() => getDetails(item?.place_id)}>
                                  <List.Item.Meta title={item.description} />
                                </List.Item>
                              </>
                            )}
                          />
                        </div>
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name={'city'}
                      rules={[
                        {
                          required: true,
                          message: "Please input your city!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your city" onKeyPress={props.onlyTextHandler} />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name={"region"}
                      rules={[
                        {
                          required: true,
                          message: "Please input your province!",
                        },

                      ]}
                    >
                      <Input placeholder="Enter your province" onKeyPress={props.onlyTextHandler} />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name="postal_code"
                      rules={[
                        {
                          required: true,
                          message: "Please input your postal code!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your postal code" value={adress?.postal_code} />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name="taxId"
                      rules={[
                        {
                          required: true,
                          message: "Please input your business tax id!",
                        },
                        {
                          pattern: new RegExp(
                            "^[A-Za-z0-9\-]{9}"
                          ),
                          message: "please enter a valid tax id",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        maxLength={9}
                        minLength={9}
                        onKeyPress={props.onlyNumbersHandler}
                        placeholder="Enter tax id" />
                    </Form.Item>
                  </div>
                  <div className="col-12 upload-file-div">
                    <Form.Item
                      className="position-relative"
                      label={false}
                      name="businessDocument"
                      rules={[
                        {
                          required: true,
                          message: "Please input your l_name!",
                        },
                      ]}
                    >
                      <Upload
                        fileList={[]}
                        customRequest={handleDocumentUpload}
                      >
                        <Button>
                          {pdf ? pdf : "Upload Incorporation Document"}
                          <div className="icon-beige-div">
                            {!pdf ? <img
                              src="/assets/icons/upload-primary-icon.svg"
                              alt=""
                              className="img-fluid"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please input your incoporation document!",
                                },
                              ]}
                            /> : (
                              <img
                                alt={""}
                                src={"/assets/icons/cross.svg"}
                                onClick={() => {
                                  setPdf('')
                                  setDisabled(true)
                                }} />
                            )}
                          </div>
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </SpinWrapper>
      </>
      )}
      {showMap && lat && lon && current === 1 && (
        <>
          <div className="col-12 text-center steps-form-heading">
            <h5>Confirm your location</h5>
          </div>
          <GoogelComponent
            lat={lat}
            lon={lon}
            form={form}
            form1Ref={form1Ref}
            profileData={profileData}
            setAddress={setAddress}
            address={adress?.address ? adress?.address : ""}
            region={adress?.address ? adress?.address : ""}
            zipcode={adress?.address ? adress?.address : ""}
          />
        </>
      )}
    </>
  );
};
export default BusinessDetails;
