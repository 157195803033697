import React from "react";
import "./landing.scss";
import "../../styles/form.scss";
import CustomHeader from "../../common/landing/header-footer/CustomHeader";
import CustomFooter from "../../common/landing/header-footer/CustomFooter";
import HomeBanner from "../../common/landing/HomeBanner";
import HowItWorks from "../../common/landing/HowItWorks";
import SkyQuickWeb from "../../common/landing/SkyQuickWeb";
import OurFeatures from "../../common/landing/OurFeatures";
import ContactUsHome from "../../common/landing/ContactUsHome";
import { useRef } from "react";

const Home = () => {
  const homeRef = useRef(null);
  const contactRef = useRef(null);
  const howRef = useRef(null);
  const aboutRef = useRef(null);
  return (
    <>
      <CustomHeader homeRef={homeRef} howRef={howRef} contactRef={contactRef} aboutRef={aboutRef}/>
      <div className="container-fluid banner-fluid">
        <HomeBanner homeRef={homeRef}/>
      </div>
      <div className="container-fluid how-it-works-fluid">
        <div className="container">
          <HowItWorks howRef={howRef} />
        </div>
      </div>
      <div className="container-fluid sky-quick-web-fluid">
        <div className="container h-100">
          <SkyQuickWeb aboutRef={aboutRef}/>
        </div>
        <div className="position-absolute sky-bottom-layer" />
      </div>
      <div className="container-fluid how-it-works-fluid our-features-fluid">
        <div className="container">
          <OurFeatures />
        </div>
      </div>
      <div className="container-fluid contact-us-fluid">
        <div className="container">
          <ContactUsHome navRef={contactRef} />
        </div>
      </div>
      <CustomFooter howRef={howRef} contactRef={contactRef} aboutRef={aboutRef}/>
    </>
  );
};

export default Home;
