import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Modal, message, Input } from "antd";
import AddMoreServices from "./AddmoreServices";
import AddServices from "../add-Services/AddServices";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import Payments from "../payment/Payments";
import SpinWrapper from "../../../common/Loader/SpinWrapper";

const EditServices = ({ serviceDetails, setPage, hasMore, getServicedData }) => {
  const serviceObj = {
    title: "",
    price: "",
    imgUrl: "",
    description: "",
    subCatId: "",
    subCatName: "",
  };
  const [showPopup, setShowPopup] = useState(false);
  const [editServices, setEditServices] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [services, setServices] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const listInnerRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    getServiceCategory();
  }, []);

  useEffect(() => {
    if (serviceDetails?.length) {
      setCategoryValue(serviceDetails[0].catName);
      getSubCategories(serviceDetails[0].catName, services);
      const currentSelect = serviceDetails?.filter(
        (item) => item.catId === serviceDetails[0].catId
      );
      form.setFieldValue("service_category", serviceDetails[0].catName);
      setEditServices(currentSelect);
      currentSelect.map((item, ind) => {
        form.setFieldValue(`service_name_edit-${ind}`, item?.title || "");
        form.setFieldValue(`service_fee_edit-${ind}`, item?.price || "");
        form.setFieldValue(
          `service_description_edit-${ind}`,
          item?.description || ""
        );
        form.setFieldValue(
          `subCategories_edit-${ind}`,
          item?.subCatName || null
        );
      });
    }
  }, [serviceDetails?.length]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);

      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (hasMore) {
          setPage((prev) => prev + 1);
        }
      }
    }
  };

  const getSubCategories = (value, list) => {
    const service = list.find((item) => item.value == value);
    axiosInstance
      .post("/admin/subcategory/list", {
        categoryId: service?.id,
        page: 1,
        pageLimit: 10,
      })
      .then((resp) => {
        const modifiedArr = resp.data.output.list.map((i) => ({
          value: i._id,
          label: i.name,
        }));
        setSubCategoryList(modifiedArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServiceCategory = () => {
    axiosInstance
      .post("/admin/category/list", { page: 1, pageLimit: 10 })
      .then((res) => {
        const modifiedArr = res.data.output.list.map((i) => ({
          value: i.name,
          label: i.name,
          id: i._id,
        }));
        setServices(modifiedArr);
        if (serviceDetails) getSubCategories(serviceDetails[0]?.catName, modifiedArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddMore = () => {
    setEditServices([...editServices, serviceObj]);
    form.setFieldValue(`service_name_edit-${editServices.length}`, "");
    form.setFieldValue(`service_fee_edit-${editServices.length}`, "");
    form.setFieldValue(`service_description_edit-${editServices.length}`, "");
    form.setFieldValue(`subCategories_edit-${editServices.length}`, null);
  };

  const onClickClose = () => {
    setShowPopup(false);
  };

  const handleAddServicesToTopList = () => {
    setShowPopup(true);
  };

  const handleDeleteService = async (id, ind) => {
    if (editServices.length === 1) {
      message.error("Business should have at least one service");
      return;
    }
    if (id) {
      try {
        setLoader(true);
        await axiosInstance.delete(`business/deleteService/${id}`);
        let newList = [...editServices];
        newList = newList.filter((it, index) => index !== ind);

        setEditServices(newList);
        if (!newList?.length) {
          setEditServices([serviceObj]);
          form.setFieldValue("service_name_edit-0", "");
          form.setFieldValue("service_fee_edit-0", "");
          form.setFieldValue("service_description_edit-0", "");
          form.setFieldValue("subCategories_edit-0", null);
        } else {
          newList.forEach((item, ind) => {
            form.setFieldValue(`service_name_edit-${ind}`, item?.title || "");
            form.setFieldValue(`service_fee_edit-${ind}`, item?.price || "");
            form.setFieldValue(
              `service_description_edit-${ind}`,
              item?.description || ""
            );
            form.setFieldValue(
              `subCategories_edit-${ind}`,
              item?.subCatName || null
            );
          });
        }
        setLoader(false);
        message.success("Service deleted successfully");
      } catch (error) {
        message.error("Error while deleting service:");
      }
    } else {
      let newList = [...editServices];
      newList = newList.filter((it, index) => index !== ind);
      setEditServices(newList);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const list = editServices?.map((item) => {
        return {
          _id: item._id,
          title: item.title,
          imgUrl: item.imgUrl,
          price: item.price,
          description: item.description,
          subCatId: item.subCatId,
        };
      });

      const service = services.find((item) => item.value == categoryValue);

      const payload = {
        catId: editServices[0]?.catId || service.id,
        serviceDetails: list,
      };

      await axiosInstance.post("/business/addService", payload).then((res) => {
        message.success("Your services saved successfully");
        getServicedData();
      });
    } catch (error) {
      message.error("Error while saving data:");
    }
  };

  const handleServiceChange = (id, data, ind) => {
    const newList = [...editServices];
    if (id) {
      const itemI = newList.findIndex((it) => it._id === id);
      if (itemI >= 0) {
        newList[itemI] = data;
        validate(newList);
        setEditServices(newList);
      } else {
        newList[0] = data;
        validate(newList);
        setEditServices(newList);
      }
    } else {
      newList[ind] = data;
      validate(newList);
      setEditServices(newList);
    }
  };

  const validate = (newList) => {
    const invalid = newList.some(
      (item) =>
        !item.title ||
        !item.subCatId ||
        !item.price ||
        !item.description ||
        !item?.imgUrl
    );
    if (invalid) {
      setDisabled(true);
    } else setDisabled(false);
  };

  return (
    <SpinWrapper loader={loader || imageLoader}>
      <Form name="editProfile" form={form}>
        <div className="setting-card row overflow-hidden">
          <div className="col-12 common-form-div settings-form-div">
            <h3 className="heading edit-services-heading">Edit Services</h3>
            <Row
              gutter={16}
              className="d-flex justify-content-center align-items-center"
            >
              <Form.Item
                className="position-relative w-100"
                label={false}
                name="service_category"
                rules={[
                  {
                    required: true,
                    message: "Please select your service category!",
                  },
                ]}
              >
                <Input
                  className="category-input"
                  readOnly
                  defaultValue={categoryValue || ""}
                />
              </Form.Item>
            </Row>
            {editServices?.length > 0 && !loader && (
              <div
                className="services-list"
                onScroll={onScroll}
                ref={listInnerRef}
              >
                {editServices?.map((editService, ind) => (
                  <AddMoreServices
                    edit
                    ind={ind}
                    setLoader={setImageLoader}
                    serviceData={editService}
                    handleServiceChange={handleServiceChange}
                    onDelete={() => handleDeleteService(editService._id, ind)}
                    options={subCategoryList}
                    listInnerRef={listInnerRef}
                    onScroll={onScroll}
                  />
                ))}
              </div>
            )}
            <div className="add-more-container">
              <Button
                type="primary"
                className="add-more-btn"
                onClick={handleAddMore}
              >
                Add more
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="add-services-btn"
                onClick={handleAddServicesToTopList}
              >
                Add services to top listing
              </Button>
            </div>
          </div>
        </div>
        <div className="btn-container mb-sm-4 mb-xl-0">
          <Button
            type="primary"
            htmlType="submit"
            className="save-button"
            onClick={handleSaveChanges}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </Form>

      {showPopup && (
        <Modal
          open={showPopup}
          closable={false}
          onCancel={() => setShowPopup(false)}
          width={820}
          className="common-modal settings-service-modal"
          centered
          header={null}
          footer={null}
        >
          <>
            <div onClick={onClickClose}>
              <img
                src="/assets/icons/close-icon.svg"
                alt="close"
                className="close-icon"
              />
            </div>
            <AddServices addnewClass={true} from='edit' />
          </>
        </Modal>
      )}
    </SpinWrapper>
  );
};

export default EditServices;
