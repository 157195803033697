import React from "react";

const GetPaid = () => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="63bf2377c939e027ae5b0ac4_icon-crypto-supported-pastel-x-webflow-template.svg"
        clip-path="url(#clip0_548_15808)"
      >
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 8.72667C0 4.18321 3.68321 0.5 8.22667 0.5H18.0987C22.1272 0.5 25.4794 3.39566 26.1875 7.21916C30.011 7.92725 32.9067 11.2795 32.9067 15.308V25.18C32.9067 29.7235 29.2235 33.4067 24.68 33.4067H8.22667C3.68321 33.4067 0 29.7235 0 25.18V8.72667ZM18.0987 3.79067C20.2479 3.79067 22.0762 5.16421 22.7538 7.08134H3.57154C4.24915 5.16421 6.07751 3.79067 8.22667 3.79067H18.0987ZM23.0347 23.5347C24.8521 23.5347 26.3254 22.0614 26.3254 20.244C26.3254 18.4266 24.8521 16.9533 23.0347 16.9533C21.2173 16.9533 19.744 18.4266 19.744 20.244C19.744 22.0614 21.2173 23.5347 23.0347 23.5347Z"
          fill="#112047"
        />
      </g>
      <defs>
        <clipPath id="clip0_548_15808">
          <rect
            width="33"
            height="33"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GetPaid;
