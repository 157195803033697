// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { axiosInstance } from "../../controller/axiosInterceptor";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "lma-prod.firebaseapp.com",
  projectId: "lma-prod",
  storageBucket: "lma-prod.appspot.com",
  messagingSenderId: "376374511059",
  appId: "1:376374511059:web:7331ad2a3da73c1a945b26",
  measurementId: "G-JKZ8XFY3PR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const getFCMToken = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // console.log("Notification User Permission Granted.")
      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((token) => {
          if (token) {
            saveToken(token);
          } else {
            // console.log("Token not found", token);
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

const saveToken = (token) => {
  axiosInstance
    .post("business/update/notification-status", {
      isNotification: true,
      fcmToken: token,
    })
    .then((res) => {
      // console.log("token saved", res.data);
    })
    .catch((err) => {
      console.log("error while saving token", err);
    });
};

export { getFCMToken, onMessage, messaging };
